<template>
    <div class="action-schedule">
        <template v-if="(me.gender && message.mtype.includes('-a')) || message.mtype.includes('-b')">
            <div class="message">
                <div class="message-top">
                    <img :src="require('@/assets/images/icon-hand.png')" alt="" />
                    <span>약속잡기를 시작합니다!</span>
                </div>
                <div class="message-content">
                    <div v-if="isAtype" class="desc">먼저 일정을 입력해주세요. 결제가 완료되면 약속이 확정됩니다.</div>
                    <div v-else class="desc">여성분이 가능한 일정을 보내주셨어요. 일정과 지역을 정해주세요.</div>
                    <div class="grey-box m-b-8">
                        <div class="user-profile">
                            <img :src="photoUrl" alt="" />
                            <div class="profile-info">
                                <div class="age" v-html="age" />
                                <div class="region" v-html="content.region" />
                                <div class="job" v-html="content.job" />
                            </div>
                        </div>
                    </div>
                    <div class="grey-box">
                        <ScheduleProgress :mode="isAtype ? 'a' : 'b'" :selected="1" />
                    </div>
                </div>
                <!-- 분기처리 추가 -->
                <div v-if="userRatePlans.onGoing" class="info m-t-8 m-b-16">
                    <img class="m-r-4" src="@/assets/images/icons/info_pink.png" width="16px" height="16px" />

                    <div class="text" v-html="'약속 확정시 만남권 1회가 사용됩니다'" />
                </div>
            </div>
            <BottomButton
                :disabled="disabled"
                @click="onClickEnterSchedule"
                :label="`약속잡기 시작`"
                :nonFixed="true"
            />
        </template>
        <template v-else>
            <div class="message">
                <div class="message-top m-b-16">
                    <img :src="require('@/assets/images/icon-hand.png')" alt="" />
                    <span>매칭을 축하드립니다!</span>
                </div>
                <div class="message-content">
                    <div class="m-b-8">
                        <span>'{{ content.job }}/{{ content.age }}/{{ content.region }}'</span> 회원과 매칭 되었어요!
                    </div>
                    <div>현재 여성분이 일정을 선택하고 있어요. 선택이 완료되면 다시 안내드릴게요:)</div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import ScheduleProgress from './ScheduleProgress'

export default {
    name: 'ActionScheduleStartA',
    props: ['message'],
    components: {
        ScheduleProgress,
    },
    computed: {
        me() {
            return this.$store.getters.me
        },
        content() {
            return this.$mustParse(this.message.content)
        },
        photoUrl() {
            if (!this.me.gender && this.message.mtype.includes('-a')) return null
            return this.content.urls[0] || null
        },
        age() {
            return `${this.$options.filters.asAge(this.content.birthday)}세`
        },
        isAtype() {
            return this.message.mtype === 'schedule-start-a'
        },
        disabled() {
            return this.content.status === 1
        },
        userRatePlans() {
            return this.$store.getters.userRatePlans || {}
        },
        validTickets() {
            return this.$store.getters.validTickets
        },
        PostpaidRatePlan() {
            if (!this.userRatePlans.onGoing) return {}
            console.log('94 : ', this.$store.getters.products.all)
            return this.$store.getters.products.all.find(item => item.id === this.userRatePlans.onGoing.product_id)
        },
        selectPostpaidTicket() {
            if (!this.PostpaidRatePlan) return {}
            return this.$store.getters.products.ticket.find(
                item => item.code === `ticket_${this.PostpaidRatePlan.code}`
            )
        },
        hasUnpaid() {
            return (this.me || {}).products.some(p => {
                return p.product_id === 62
            })
        },
    },
    methods: {
        onClickEnterSchedule() {
            if (!this.userRatePlans.onGoing && !this.hasUnpaid) {
                this.$modal.basic({
                    title: '프로그램 이용이 만료되었습니다',
                    body: '약속을 진행하려면 프로그램을 구매해주세요',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
            } else {
                if (this.PostpaidRatePlan.plan_type === 'postpaid' && this.validTickets.length === 0) {
                    // 후불제 product_id 조건 임시로 한건데 onGoing product 의 plan_type === 'postpaid' 인지 확인해야함
                    this.$modal
                        .basic({
                            body: '현재 보유하신 만남권이 없습니다. 약속을 진행하려면 만남권을 구매해주세요.',
                            buttons: [
                                {
                                    label: 'CANCEL',
                                    class: 'btn-default',
                                },
                                {
                                    label: 'CONFIRM',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                        .then(idx => {
                            if (idx === 1) {
                                console.log('134: ', this.selectPostpaidTicket)
                                this.$store.commit('setSelectedProduct', this.selectPostpaidTicket)
                                this.$router.push({
                                    name: 'TicketPaymentMethodsPage',
                                    // params: { categoryName: this.category.category },
                                })
                            }
                        })
                } else {
                    this.$stackRouter.push({
                        name: 'PromisePage',
                        props: {
                            message: this.message,
                            isAtype: this.isAtype,
                            userInfo: {
                                message: this.message,
                                photoUrl: this.photoUrl,
                                age: this.age,
                                region: this.content.region,
                                job: this.content.job,
                                schedule_id: this.content.schedule_id,
                                date_confirmed: this.content.date_confirmed,
                                meet_place: this.content.meet_place,
                                meet_type_confirmed: this.content.meet_type_confirmed,
                            },
                        },
                    })
                }
            }
        },
    },
}
</script>

<style scoped lang="scss">
.info {
    background: rgba(255, 61, 107, 0.07);
    border-radius: 8px;
    padding: 4px 8px;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #111111;
    display: flex;
    align-items: center;
}
.message-content {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #111111;
    span {
        font-weight: 700;
    }
}
</style>
